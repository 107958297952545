<template>
  <div>
    <BaseDialog
      :open="useDialogStore().confirmEmergency.visible"
      width="385px"

      accept-text="Volat SOS"
      dismiss-text="Ne, zpět"
      accept-button-type="danger"
      :is-accepting="isLoading"

      @accept="handleAccept"
      @dismiss="handleDismiss"
    >
      <template #header>
        <h4 class="tw-text-danger-500">
          <icon-emergency />
          <span>Volat SOS</span>
        </h4>
      </template>
      <template #content>
        <div
          class="tw-flex tw-items-center tw-justify-center tw-flex-col tw-font-medium"
        >
          <div class="tw-w-full tw-text-center">
            <span>Opravdu si přejete volat SOS?</span>
          </div>
          <VideoHelp
            class="tw-my-5"
            type="primary"
            size="default"
            youtube="GLhmNc6UCaE"
            title="Volat SOS"
          />
        </div>
      </template>
    </BaseDialog>
  </div>
</template>

<script>
export default {
  emits: ['accepted'],

  data() {
    return {
      isLoading: false,
    };
  },

  methods: {
    async handleAccept() {
      this.isLoading = true;

      const hasAccessToEmergency = await useIpLocation().hasAccessToEmergency();
      if (!hasAccessToEmergency) {
        await sleep(2000);
        useIpLocation().accessToActionDeniedMessage();
        this.isLoading = false;

        return;
      }

      this.$emit('accepted');

      // if (!useAuthStore().isLoggedIn) {
      //   this.$axios.setToken(false);
      // }

      try {
        const data = {
          type_id: 4,
          book_time: null,
        };

        const response = await useApi.post(`/client/${useAuthStore().isLoggedIn ? 'requests' : 'anon-request'}/`, { body: data });

        if (!useAuthStore().isLoggedIn) {
          useAuthStore().setAuth({
            access: response.access,
            refresh: response.refresh,
            user: response.user || {},
          });
          await sleep(200);
          await useAuthStore().fetchUser();

          this.closeDialog();
          await sleep(800);
        }

        this.$router.push(this.localePath(`/requests/${response.id}/waiting`));
      } catch (error) {
        console.error(error);
        ElMessage.error({
          message: 'Omlouváme se, ale došlo k chybě při vytváření požadavku.',
          duration: 4000,
          showClose: true,
        });
        this.$router.push(this.localePath('/requests'));
      }

      this.closeDialog();
      this.isLoading = false;
    },
    handleDismiss() {
      this.closeDialog();
    },
    closeDialog() {
      useDialogStore().close('confirmEmergency');
    },
  },
};
</script>
